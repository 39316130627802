define('ember-sf-pageable/mixins/pageable-route', ['exports', 'ember-sf-pageable/mixins/reset-query-params'], function (exports, _resetQueryParams) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service,
        isEmpty = Ember.isEmpty;


    var assign = Ember.assign || Ember.merge;

    exports.default = Ember.Mixin.create(_resetQueryParams.default, {
        // TODO: Should be replaced with *jsonapi* service from ember-sf-jsonapi-model.
        ajax: service(),

        queryParams: {
            page: { refreshModel: true },
            size: { refreshModel: true }
        },

        pageNum: null,
        pageSize: null,

        // Can be used to track 'loading' status of multiple objects (loading['users'], etc.).
        isLoading: {},

        model: function model(params) {
            var page = params.page;
            this.set('pageNum', !page || isNaN(page) ? 1 : Math.floor(Math.abs(page)));

            var size = params.size;
            if (size && !isNaN(size) && size % 1 === 0) {
                this.set('pageSize', Math.abs(size));
            }

            return Ember.RSVP.hash(assign({
                isLoading: this.get('isLoading')
            }, this.pageableModel(params)));
        },
        pageableModel: function pageableModel() {
            throw new Ember.Error('Please provide the implementation for PageableRouteMixin.pageableModel(params)');
        },
        getPageParams: function getPageParams() {
            return assign({}, this.get('pageNum') ? { page: this.get('pageNum') } : null, this.get('pageSize') ? { size: this.get('pageSize') } : null);
        },
        getFilterParams: function getFilterParams(params) {
            var filterParams = this.get('filterParams');
            var result = {};

            if (isEmpty(filterParams)) {
                return result;
            }

            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = Object.keys(params)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var p = _step.value;

                    if (filterParams.indexOf(p) !== -1) {
                        result[p] = params[p];
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            return result;
        },
        loadPage: function loadPage(descriptor, modelClass, url, params) {
            var _this = this;

            this.set('isLoading.' + descriptor, true);

            return this.get('ajax').fetchPage(modelClass, url, this.getPageParams(), this.getFilterParams(params)).finally(function () {
                _this.set('isLoading.' + descriptor, false);
            });
        },


        actions: {
            prevPage: function prevPage() {
                this.transitionTo({
                    queryParams: {
                        page: this.decrementProperty('pageNum') || undefined,
                        size: this.get('pageSize') || undefined
                    }
                });

                return false;
            },
            nextPage: function nextPage() {
                this.transitionTo({
                    queryParams: {
                        page: this.incrementProperty('pageNum') || undefined,
                        size: this.get('pageSize') || undefined
                    }
                });

                return false;
            }
        }
    });
});