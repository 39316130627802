define('ember-sf-loadable-content/components/loadable-content', ['exports', 'ember-sf-loadable-content/templates/components/loadable-content'], function (exports, _loadableContent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var on = Ember.on;
    exports.default = Ember.Component.extend({
        layout: _loadableContent.default,

        classNames: ['loadable-content'],

        _loadableContent_inserted: on('didInsertElement', function () {
            var child = this.$().children().first();

            var _arr = ['display', 'float'];
            for (var _i = 0; _i < _arr.length; _i++) {
                var prop = _arr[_i];
                this.$().css(prop, child.css(prop));
            }
        })
    });
});