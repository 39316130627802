define('ember-sf-pageable/mixins/reset-query-params', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var isPresent = Ember.isPresent;
    exports.default = Ember.Mixin.create({
        resetController: function resetController(controller, isExiting) {
            this._super.apply(this, arguments);

            if (isExiting) {
                var queryParams = this.get('queryParams');

                if (isPresent(queryParams)) {
                    Object.keys(queryParams).forEach(function (param) {
                        return controller.set(param, undefined);
                    });
                }
            }
        }
    });
});