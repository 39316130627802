define('ember-sf-modal/mixins/modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service,
        typeOf = Ember.typeOf;
    exports.default = Ember.Mixin.create({
        modal: service(),

        init: function init() {
            this._super.apply(this, arguments);

            this._copyModelProperties();
        },


        // If required this method may be called directly in the extending class,
        // but the properties still will be copied only once.
        _copyModelProperties: function _copyModelProperties() {
            var _this = this;

            if (!this.get('_modelPropsCopied')) {
                // Copy all model properties as component own properties.
                var model = this.get('model');
                if (!model) {
                    return;
                }

                Object.keys(model).forEach(function (key) {
                    if (typeOf(model[key]) !== 'function') {
                        _this.set(key, model[key]);
                    }
                });

                this.set('_modelPropsCopied', true);
            }
        }
    });
});