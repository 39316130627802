define('ember-sf-loadable-content/components/loading-animation', ['exports', 'ember-sf-loadable-content/templates/components/loading-animation'], function (exports, _loadingAnimation) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _loadingAnimation.default,

        classNames: ['loading-spinner']
    });
});