define('ember-sf-modal/services/modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var __removeModal = function __removeModal(modal) {
        modal.trigger('removeModal');

        modal.set('name', null);
        modal.set('model', null);
        modal.set('element', null);
    };

    exports.default = Ember.Service.extend(Ember.Evented, {
        name: null,
        model: null,
        element: null,

        initialize: function initialize(element, onCloseHandler) {
            var _this = this;

            this.set('element', element);

            this.get('element').on('hidden.bs.modal', function () {
                if (onCloseHandler) {
                    onCloseHandler();
                }

                __removeModal(_this);
            }).modal();
        },


        // Generic modal methods.
        show: function show(name, model) {
            this.trigger('renderModal', name, model);

            this.set('name', name);
            this.set('model', model);
        },
        hide: function hide() {
            var _this2 = this;

            return new Ember.RSVP.Promise(function (resolve) {
                if (_this2.get('element').hasClass('in')) {
                    // Modal is visible.

                    _this2.get('element').on('hidden.bs.modal', function () {
                        resolve();
                    }).modal('hide');
                } else {
                    // Modal is already hidden (this should never happen, but left here just in case).

                    __removeModal(_this2);
                    resolve();
                }
            });
        },


        // Specific modal builders.
        showConfirmation: function showConfirmation(title, message, action) {
            this.show('confirmation-modal', {
                title: title,
                message: message,
                confirmAction: action
            });
        }
    });
});