define('ember-sf-modal/initializers/modal-service', ['exports', 'ember-sf-modal/services/modal'], function (exports, _modal) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(application) {
        application.register('service:modal', _modal.default);
    }

    exports.default = {
        name: 'modal-service',
        initialize: initialize
    };
});