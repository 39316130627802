define('ember-sf-jsonapi-model/mixins/jsonapi-model', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var typeOf = Ember.typeOf,
        A = Ember.A;


    var _convertIncludesToHashMap = function _convertIncludesToHashMap() {
        var includes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        var result = {};

        includes.forEach(function (item) {
            // Init object for a record type if required.
            if (!result[item.type]) {
                result[item.type] = {};
            }

            result[item.type][item.id] = item;
        });

        return result;
    };
    var _convertReferencedRecord = function _convertReferencedRecord(recordRef, modelClass, includes) {
        if (!recordRef.type || !recordRef.id) {
            throw new Ember.Error('Resource Identifier Object must contain \'type\' and \'id\' attributes');
        }

        if (includes[recordRef.type]) {
            var expandedRecord = includes[recordRef.type][recordRef.id];

            if (expandedRecord) {
                return modelClass.fromJson(expandedRecord, includes);
            }
        } else {
            // If the referenced record is missing in the includes - instantiate it
            // just with id not to break the reversal conversion.

            return modelClass.create({ id: recordRef.id });
        }

        return null;
    };
    var _convertToJsonApiObject = function _convertToJsonApiObject(modelClass, model) {
        if (typeOf(model) !== 'instance' && typeOf(model) !== 'object') {
            throw new Ember.Error('Unexpected model type [' + typeOf(model) + ']. Should be an instance of Ember.Object or a plain JavaScript object');
        }

        var classMeta = modelClass.meta;
        var result = {
            type: classMeta.type
        };

        Object.keys(model).forEach(function (key) {
            if (key === 'id') {
                result[key] = model[key];
            } else if (classMeta.relationships && classMeta.relationships[key]) {
                if (!result['relationships']) {
                    result['relationships'] = {};
                }

                var referencedClass = classMeta.relationships[key];

                if (typeOf(model[key]) === 'instance') {
                    result['relationships'][key] = {
                        data: { type: referencedClass.meta.type, id: model[key].id }
                    };
                } else if (typeOf(model[key]) === 'array') {
                    var refsArray = A([]);

                    model[key].forEach(function (refObject) {
                        refsArray.pushObject({ type: referencedClass.meta.type, id: refObject.id });
                    });

                    result['relationships'][key] = { data: refsArray };
                }
            } else {
                if (!result['attributes']) {
                    result['attributes'] = {};
                }

                result['attributes'][key] = model[key];
            }
        });

        return result;
    };

    exports.default = Ember.Mixin.create({
        fromJson: function fromJson(json) {
            var includes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

            // Convert includes to a hash map if passed as an array.
            if (typeOf(includes) === 'array') {
                includes = _convertIncludesToHashMap(includes);
            }

            var classMeta = this.meta;
            var result = this.create();

            if (json['id'] && json['type']) {
                result.set('id', json['id']);
            } else {
                throw new Ember.Error('Resource Object must contain \'type\' and \'id\' attributes');
            }

            if (json['attributes']) {
                var attributes = json['attributes'];

                Object.keys(attributes).forEach(function (key) {
                    result.set(key, attributes[key]);
                });
            }

            if (json['relationships']) {
                var relationships = json['relationships'];

                Object.keys(relationships).forEach(function (key) {
                    var ref = relationships[key]['data'];
                    var relationshipClass = classMeta.relationships && classMeta.relationships[key];

                    if (!relationshipClass) {
                        return;
                    }

                    if (typeOf(ref) === 'object') {
                        var convertedRecord = _convertReferencedRecord(ref, relationshipClass, includes);
                        if (convertedRecord) {
                            result.set(key, convertedRecord);
                        }
                    } else if (typeOf(ref) === 'array' && ref.length > 0) {
                        var objectsArray = A([]);

                        ref.forEach(function (refItem) {
                            var convertedRecord = _convertReferencedRecord(refItem, relationshipClass, includes);
                            if (convertedRecord) {
                                objectsArray.pushObject(convertedRecord);
                            }
                        });

                        result.set(key, objectsArray);
                    }
                });
            }

            return !result.fromJSON ? result : result.fromJSON();
        },
        fromJsonArray: function fromJsonArray(jsonData, jsonIncludes) {
            var _this = this;

            var result = A([]);
            var includes = !jsonIncludes ? {} : _convertIncludesToHashMap(jsonIncludes);

            jsonData.forEach(function (record) {
                result.pushObject(_this.fromJson(record, includes));
            });

            return result;
        },
        stringify: function stringify(object) {
            var _this2 = this;

            var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'data';

            if (typeOf(object) === 'instance' || typeOf(object) === 'object') {
                var preparedObject = !object.toJSON ? object : object.toJSON();
                var jsonApiObject = _convertToJsonApiObject(this, preparedObject);
                return '{"' + name + '":' + JSON.stringify(jsonApiObject) + '}';
            } else if (typeOf(object) === 'array') {
                var jsonApiObjects = object.map(function (o) {
                    var preparedObject = !o.toJSON ? o : o.toJSON();
                    return _convertToJsonApiObject(_this2, preparedObject);
                });
                return '{"' + name + '":' + JSON.stringify(jsonApiObjects) + '}';
            } else {
                throw new Ember.Error('Unexpected object type [' + typeOf(object) + ']. ' + 'Should be either an instance of Ember.Object or plain JavaScript object ' + 'or an array of the previously mentioned types');
            }
        }
    });
});