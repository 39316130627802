define('ember-sf-pageable/mixins/searchable-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var assign = Ember.assign || Ember.merge;

    exports.default = Ember.Mixin.create({
        searchTransition: function searchTransition(routeName, searchParams) {
            searchParams = searchParams || {};

            // Reset page number.
            var queryParams = assign({ page: 1 }, searchParams);

            this.transitionTo(routeName, { queryParams: queryParams });
            this.refresh();
        }
    });
});