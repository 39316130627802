define('ember-sf-modal/components/modal-window', ['exports', 'ember-sf-modal/templates/components/modal-window'], function (exports, _modalWindow) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service,
        computed = Ember.computed,
        on = Ember.on;
    exports.default = Ember.Component.extend({
        layout: _modalWindow.default,

        modal: service(),

        sizeClass: computed('size', function () {
            switch (this.get('size')) {
                case 'small':
                    return 'modal-sm';
                case 'large':
                    return 'modal-lg';
                case 'login-dialog':
                    return 'login-modal-dialog';
            }

            return '';
        }),

        _modalWindow_inserted: on('didInsertElement', function () {
            this.get('modal').initialize(this.$('.modal'), this.attrs.onClose);
        })
    });
});