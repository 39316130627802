define('ember-sf-modal/instance-initializers/application-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    var RSVP = Ember.RSVP;
    function initialize(appInstance) {
        var modalService = appInstance.lookup('service:modal');
        var appRoute = appInstance.lookup('route:application');

        var routeEntryComplete = false;

        appRoute.reopen({
            activate: function activate() {
                routeEntryComplete = true;

                return this._super.apply(this, arguments);
            },
            beforeModel: function beforeModel(transition) {
                var _this = this;

                // Wrapped into RSVP.resolve() to make sure the result of _super() is a promise.
                var superPromise = RSVP.resolve(this._super.apply(this, arguments));

                return superPromise.then(function (result) {
                    if (!_this.get('_modalEventListenersAssigned')) {
                        Ember.A(['renderModal', 'removeModal']).forEach(function (event) {
                            modalService.on(event, function () /*name, model*/{
                                var target = routeEntryComplete ? this : transition;

                                Array.prototype.unshift.call(arguments, event);
                                target.send.apply(target, arguments);
                            }.bind(_this));
                        });

                        _this.set('_modalEventListenersAssigned', true);
                    }

                    return result;
                });
            },


            actions: {
                renderModal: function renderModal(name, model) {
                    this.render(name + '-wrapper', {
                        into: 'application',
                        outlet: 'modal',
                        model: model
                    });
                },
                removeModal: function removeModal() {
                    this.disconnectOutlet({
                        outlet: 'modal',
                        parentView: 'application'
                    });
                }
            }
        });
    }

    exports.default = {
        name: 'application-route',
        initialize: initialize
    };
});