define('ember-sf-modal/components/confirmation-modal', ['exports', 'ember-sf-modal/templates/components/confirmation-modal', 'ember-sf-modal/mixins/modal'], function (exports, _confirmationModal, _modal) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_modal.default, {
        layout: _confirmationModal.default,

        actions: {
            confirmAction: function confirmAction() {
                this.attrs.confirmAction();

                this.get('modal').hide();
            }
        }
    });
});